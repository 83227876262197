import { faBuilding, faEnvelope, faGlobe, faPerson, faUser, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Col, Container, Nav, NavLink, Row } from "react-bootstrap";
import './store-information.css'
import { FaEnvelope, FaFax, FaLocationDot, FaPhoneFlip, FaRegEnvelope } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const data = [

    {
        title: 'Contact',
        subMenu: [
            // {
            //     location: 'Adelaide, Australia',
            //     office: 'Global Sales Head',
            //     address: 'Anthony Manousakis',
            //     website: 'tony@ecopackagingdirect.com.au',
            //     phone: '+61 0400 050 181'

            // },
            // {
            //     location: 'Zhejiang, China',
            //     office: 'Manufacturing Head',
            //     address: 'Kelly Bai',
            //     website: 'kelly@ecopackagingdirect.com.cn',
            //     phone: '+861 7821 112 229'

            // },

            {
                location: 'Surry Hills, NSW 2010',
                office: 'Regional Operations (ME&A, India & SEA)',
                address: 'Level 1, 457-459 Elizabeth St,',
                website: 'sales@biobox.au',
                phone: '+61 493 624 746'
            },
            // {
            //     location: 'NORTH AMERICA',
            //     address: 'Vagelis Karatzas',
            //     website: 'vagelis@ecopackagingdirect.world',
            //     phone: '+1 727 550 7874'

            // },
            // {
            //     location: 'INDIA & SOUTHEAST ASIA',
            //     address: 'Appachu Thammaiah',
            //     website: 'appachu@ecopackdirect.com',
            //     phone: '+91 9972722199'

            // },
            // {
            //     location: 'SOUTH AMERICA',
            //     address: 'Ignacio',
            //     website: 'espinosaignacio@ecopackagingdirect.world',
            //     phone: '+549 2236 816 046'

            // },


            // {
            //     location: 'TURKEY',
            //     address: '',
            //     website: '',
            //     phone: '+90 5336 644 480'

            // },
            // {
            //     location: 'GREECE & CYPRUS',
            //     address: '',
            //     website: '',
            //     phone: '+30 697 8544 319'

            // },
        ]
    },

]
const StoreInfomation = () => {
    const [openSubMenu, setOpenSubMenu] = useState(window.innerWidth >= 576);

    const toggleSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    };

    useEffect(() => {
        // Hide the submenu on smaller screens by default
        if (window.innerWidth < 576) {
            setOpenSubMenu(false);
        }
    }, []);

    return <>
        {data.map((item: any, idx: any) => (
            <Col key={idx}>
                <h6 className="text-uppercase footer-title1 mb-4 "
                    onClick={toggleSubMenu}>
                    <b>  {item.title} </b>
                </h6>
                <Container className=" p-0 mx-2">
                    {(window.innerWidth >= 576 || openSubMenu) && (
                        <Row className="contact">
                            {item.subMenu.map((sumItem: any, idx: any) => (
                                <Col className="px-1 ">
                                    {/* <p>
                                        <FontAwesomeIcon icon={faUser} />   {sumItem.address}
                                    </p> */}
                                    {/* <p>
                                        <FontAwesomeIcon icon={faAddressCard} />  {sumItem.office}
                                    </p> */}
                                    <p>
                                        <FaLocationDot style={{ marginTop: '-2px' }} /> {sumItem.address}
                                    </p>


                                    {sumItem.location != 0 && (
                                        <p>
                                            {sumItem.location}
                                        </p>

                                    )}

                                    <p style={{ marginTop: '-2px' }} >
                                        <FaEnvelope /> {sumItem.website}
                                    </p>

                                    {/* <Col className="wrap-content">
                                        {sumItem.website != 0 && (
                                            <Row>
                                                <Col sm={6} md={1} >
                                                    <FontAwesomeIcon className="me-2 my-2 text-light" icon={faEnvelope} />
                                                </Col>
                                                <Col sm={5} md={11} className="px-1">
                                                    <p style={{ marginTop: '-2px' }} >
                                                        {sumItem.website}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col> */}
                                    <p>
                                        <FaPhoneFlip /> {sumItem.phone}
                                    </p>
                                    <br />
                                    <a
                                        className="text-light custom-nav-link"
                                        href='/contact-us'
                                    >
                                        See all Locations...
                                    </a>
                                </Col>
                            ))}
                            {/* <Col className="mt-5">
                                <a
                                    className="text-light custom-nav-link"
                                    href='/contact-us'
                                >
                                    See all Locations...
                                </a>
                            </Col> */}
                        </Row>
                    )}
                </Container>


            </Col>
        ))}
    </>


}
export default StoreInfomation